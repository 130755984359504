import React from 'react';
import ChangeThema from './themes/Themes';
import Routes from './routes/routes';
import history from './routes/history';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { useAuthState } from './contexts/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';

export default function SubApp() {
  const { newThema } = useAuthState();
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={ChangeThema(newThema)}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <ToastContainer autoClose={3000} />
          <Routes />
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
