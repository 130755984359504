import { Switch, Route } from 'react-router-dom';

//Route personalizado para estabelecer a autenticacao tive que mudar o nome pois estava confundindo com o routes de react-router-dom
import { CustomRoute } from './CustomRoute';

//Pagina Login
import Login from '../pages/Login/Login';

import HomePage from '../pages/HomePage/HomePage';

//Layout da Dashboard
import Layout from '../layouts/Layout';

//PAGINA DE RESET DE SENHA
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Clientes from '../pages/Clientes/Clientes';
import Pedidos from '../pages/Pedidos/Pedidos';
import Trocas from '../pages/Trocas/Trocas';
import Comissoes from '../pages/Comissoes/Comissoes';
import Visitas from '../pages/Visitas/Visitas';
//LINHA DE PRODUCAO  - IMPORTS

export default function Routes() {
  return (
    <>
      <Switch>
        <Route path="/login/:id" component={Login} />
        <CustomRoute
          exact
          path="/alterasenha"
          component={ResetPassword}
          isPrivate
        />
        <Layout>
          <Switch>
            <CustomRoute exact path="/" component={HomePage} isPrivate />
            <CustomRoute
              exact
              path="/clientes"
              component={Clientes}
              isPrivate
            />
            <CustomRoute exact path="/pedidos" component={Pedidos} isPrivate />
            <CustomRoute exact path="/troca" component={Trocas} isPrivate />
            <CustomRoute
              exact
              path="/comissoes"
              component={Comissoes}
              isPrivate
            />

            <CustomRoute exact path="/visitas" component={Visitas} isPrivate />

            <CustomRoute path="*" isPrivate />
          </Switch>
        </Layout>
      </Switch>
    </>
  );
}
