import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';
import { ptBR as coreptBR } from '@mui/material/locale';

export default function ChangeThema(newThema) {
  let mainTheme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: {
        primary: {
          light: '#FFFF',
          main: '#394648',
          dark: '#331832',
          contrastText: '#ffff',
          textColor: '#fff',
          success: '#018E42',
          error: '#ff5500',
          warning: '#e0afa0',
          info: '#8a817c',
          buttonColorMain: '#000000',
          buttonColorOther: '#09aaae',
          buttonColorSecondary: '#0d2149',
          backgroundRow: '#F8F4EB',
          softGreen: '#e0fee0 ',
          softOrange: '#FAD2B2',
          softRed: '#FFA286',
        },
        secondary: {
          light: '#757ce8',
          main: '#423e37',
          warning: '#fafafa',
          dark: '#002884',
          contrastText: '#263238',
        },
      },
    },
    ptBR,
    coreptBR
  );

  let primaryTheme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: {
        primary: {
          light: '#FFFF',
          main: '#f0544f',
          error: '#ff5500',
          dark: '#57423F',
          contrastText: '#ffff',
          textColor: '#fff',
          success: '#018E42',
          warning: '#ce796b',
          info: '#ffc9b5',
          buttonColorMain: '#F7ADA4',
          buttonColorOther: '#FFE5DD',
          buttonColorSecondary: '#0d2149',
          backgroundRow: '#F8F4EB',
        },
        secondary: {
          light: '#757ce8',
          main: '#423e37',
          warning: '#fafafa',
          dark: '#002884',
          contrastText: '#263238',
        },
      },
    },
    ptBR,
    coreptBR
  );
  let secondTheme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
          xxl: 2000,
        },
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: {
        primary: {
          light: '#FFFF',
          main: '#876958',
          dark: '#504239',
          contrastText: '#ffff',
          textColor: '#fff',
          success: '#018E42',
          error: '#ff5500',
          warning: '#ffffff',
          info: '#e0ad14',
          buttonColorMain: '#A9907E',
          buttonColorOther: '#675D50',
          buttonColorSecondary: '#ABC4AA',
          backgroundRow: '#F3DEBA',
        },
        secondary: {
          light: '#757ce8',
          main: '#ffffff',
          warning: '#ffffff',
          dark: '#002884',
          contrastText: '#263238',
        },
      },
    },
    ptBR,
    coreptBR
  );

  primaryTheme = responsiveFontSizes(primaryTheme);
  secondTheme = responsiveFontSizes(secondTheme);
  mainTheme = responsiveFontSizes(mainTheme);

  switch (newThema) {
    case 0:
      return primaryTheme;

    case 1:
      return secondTheme;

    default:
      return mainTheme;
  }
}
