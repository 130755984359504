import { useTheme } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function TitlePage({ title }) {
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
      spacing={{ xs: 1, sm: 1, md: 3 }}
      mt={0}
      mb={1}
      p={1}
      sx={{
        background: theme.palette.primary.info,
        borderRadius: '15px',
        width: '100%',
      }}
    >
      <Typography variant="h6" fontWeight={600} color="primary.light">
        {title}
      </Typography>
    </Stack>
  );
}
