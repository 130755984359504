import axios from "axios";

const api = axios.create({
  baseURL: "https://speed-comercio-node.herokuapp.com/",
  //baseURL: "http://192.168.0.11:3333", // rene
  // baseURL: 'http://192.168.0.21:3333',  // casa
  // baseURL: 'http://192.168.0.222:3333', // lh
  // baseURL: 'http://localhost:3333',     // lh
  //baseURL: 'http://192.168.0.157:3333', // renan
});

export default api;
