import React from "react";
import { Box, IconButton, Modal } from "@mui/material";

import { CloseTwoTone } from "@mui/icons-material";

import Spinner from "../../../../../components/Spinner/Spinner";
export default function VisualizadorImagem({
  open,
  handleClose,
  imagemSelecionada,
  loadingImagem,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
          width: 300,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            marginBottom: "5%",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <CloseTwoTone sx={{ color: "primary.error" }} />
            </IconButton>
          </Box>
        </Box>
        {loadingImagem ? (
          <Spinner />
        ) : (
          <img
            src={`data:image/jpeg;base64,${imagemSelecionada}`}
            width="100%"
            height="100%"
            alt="Produto"
          />
        )}
      </Box>
    </Modal>
  );
}
