import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Modal,
  NativeSelect,
  TextField,
  Typography,
} from '@mui/material';

import { CloseTwoTone } from '@mui/icons-material';
import { useAuthState } from '../../../../contexts/AuthContext';

import Spinner from '../../../../components/Spinner/Spinner';
import moment from 'moment';

export const CadastraVisita = ({
  open,
  onClose,
  loadingPedido,
  listaClientes,
  agendaVisita,
}) => {
  const { usuarioIDLogado } = useAuthState();
  const [clienteSelecionado, setClienteSelecionado] = useState(0);

  const [novaVisita, setNovaVisita] = useState({
    vis_cliente: clienteSelecionado,
    vis_data: moment().format('YYYY-MM-DD'),
    vis_solicitante: '',
    vis_vendedor: usuarioIDLogado,
    vis_status: 'PENDENTE',
    vis_obs: '',
  });

  const selecionaCliente = (e) => {
    setNovaVisita({ ...novaVisita, vis_cliente: e.target.value });
    setClienteSelecionado(e.target.value);
  };

  const aofechaModalCadastroPedidos = () => {
    setNovaVisita({
      vis_cliente: 0,
      vis_data: moment().format('DD/MM/YYYY'),
      vis_solicitante: '',
      vis_vendedor: usuarioIDLogado,
      vis_status: 'PENDENTE',
      vis_obs: '',
    });

    onClose();
  };
  const handleChange = (prop) => (event) => {
    setNovaVisita({ ...novaVisita, [prop]: event.target.value.toUpperCase() });
    console.log(novaVisita);
  };

  if (loadingPedido) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="Cadastro de Visitas"
        aria-describedby="Cadastro de Visitas"
        hideBackdrop
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
            height: {
              xs: '50%',
              sm: '50%',
              md: '50%',
              lg: '50%',
              xl: '50%',
              xxl: '50%',
            },
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 1,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <Spinner />
        </Box>
      </Modal>
    );
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Cadastro de Pedidos"
      aria-describedby="Cadastro de Pedidos"
      hideBackdrop
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
          height: {
            xs: '60%',
            md: '60%',
            lg: '60%',
            xl: '60%',
            xxl: '60%',
          },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            marginBottom: '5%',
          }}
        >
          <Typography variant="h6">Cadastro de Visitas</Typography>{' '}
          <Box>
            <IconButton
              onClick={() => {
                aofechaModalCadastroPedidos();
              }}
            >
              <CloseTwoTone sx={{ color: 'primary.error' }} />
            </IconButton>
          </Box>
        </Box>
        <FormControl sx={{ m: 2 }}>
          <NativeSelect
            id="selectCliente"
            value={clienteSelecionado}
            onChange={selecionaCliente}
            disabled={clienteSelecionado ? true : false}
            size="small"
          >
            <option value={0}>Selecione um Cliente</option>
            {listaClientes.map((cliente, index) => (
              <option key={index} value={cliente.codigo_cliente}>
                {cliente.nome_fantasia
                  ? cliente.nome_fantasia
                  : cliente.razao_social}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        <FormControl sx={{ m: 2 }}>
          <TextField
            value={novaVisita.vis_data}
            onChange={handleChange('vis_data')}
            id="Data"
            size="small"
            disabled={clienteSelecionado === 0 ? true : false}
            placeholder="Data Agendamento"
            type="date"
          />
        </FormControl>
        <FormControl sx={{ m: 2 }}>
          <TextField
            value={novaVisita.vis_solicitante}
            onChange={handleChange('vis_solicitante')}
            id="campoSolicitante"
            size="small"
            disabled={clienteSelecionado === 0 ? true : false}
            placeholder="Solicitante"
          />
        </FormControl>{' '}
        <FormControl sx={{ m: 2 }}>
          <TextField
            value={novaVisita.vis_obs}
            onChange={handleChange('vis_obs')}
            id="campoObs"
            size="small"
            disabled={clienteSelecionado === 0 ? true : false}
            placeholder="Obervação"
          />
        </FormControl>
        <Button onClick={() => agendaVisita(novaVisita)} variant="contained">
          Salvar
        </Button>
      </Box>
    </Modal>
  );
};
