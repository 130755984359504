import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

import { CloseTwoTone } from '@mui/icons-material';

export const ConfirmaVisita = ({
  open,
  onClose,
  visitaSelecionada,
  confirmaVisita,
}) => {
  const [motivo, setMotivo] = useState('');

  const aofechaModalCadastroPedidos = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Cadastro de Pedidos"
      aria-describedby="Cadastro de Pedidos"
      hideBackdrop
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
          height: {
            xs: '40%',
            md: '40%',
            lg: '40%',
            xl: '40%',
            xxl: '40%',
          },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            marginBottom: '5%',
          }}
        >
          <Typography variant="h6">Confirmar Visita</Typography>{' '}
          <Box>
            <IconButton
              onClick={() => {
                aofechaModalCadastroPedidos();
              }}
            >
              <CloseTwoTone sx={{ color: 'primary.error' }} />
            </IconButton>
          </Box>
        </Box>

        <FormControl sx={{ m: 2 }}>
          <TextField
            label="Histórico"
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
            id="Obs"
            size="small"
            placeholder="Digite o histórico do atendimento"
          />
        </FormControl>

        <Button
          onClick={() => confirmaVisita(visitaSelecionada, motivo)}
          variant="contained"
        >
          Salvar
        </Button>
      </Box>
    </Modal>
  );
};
