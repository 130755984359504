import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { styles } from './styles';
import moment from 'moment';
import { useAuthState } from '../../../../../contexts/AuthContext';

export const LayoutPdfImprimir = (pedidoAImprimir, totalPedido) => {
  const { empresaAtiva } = useAuthState();
  console.log(totalPedido);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoImage}>
            <Image src={`/images/empresa${empresaAtiva}.jpg`} />
          </View>
          <View style={styles.titleDocument}>
            <Text style={styles.titleText}>Pedido de Venda </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.rowH8mm}>
            <View style={styles.columnNoAlign30}>
              <Text style={styles.textTitleColumn}>DATA:</Text>
            </View>
            <View style={styles.columnNoAlign40}>
              <Text style={styles.textTitleColumn}>PEDIDO:</Text>
            </View>
            <View style={styles.column110}>
              <Text style={styles.textTitleColumn}>CLIENTE:</Text>
            </View>
          </View>
          <View style={styles.rowH8mm}>
            <View style={styles.columnNoAlign30}>
              <Text style={styles.textContentColumn}>
                {pedidoAImprimir.length > 0
                  ? moment(pedidoAImprimir[0].emissao)
                      .add(3, 'hours')
                      .format('DD/MM/YYYY')
                  : ''}
              </Text>
            </View>
            <View style={styles.columnNoAlign40}>
              <Text style={styles.textContentColumn}>
                {pedidoAImprimir.length > 0
                  ? pedidoAImprimir[0].codigo_venda
                  : ''}
              </Text>
            </View>
            <View style={styles.column120}>
              <Text style={styles.textContentColumn}>
                {pedidoAImprimir.length > 0
                  ? pedidoAImprimir[0].razao_social
                  : ''}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.rowH10mm}>
            <Text style={styles.titleSection}>Dados dos Produtos:</Text>
          </View>
        </View>
        <View style={styles.section}>
          {pedidoAImprimir.length > 0 ? (
            <View style={styles.rowH8mm}>
              <View style={styles.columnNoAlign30}>
                <Text style={styles.titleBar}>CODIGO:</Text>
              </View>
              <View style={styles.column110}>
                <Text style={styles.titleBar}>DESCRIÇÃO</Text>
              </View>
              <View style={styles.columnAlignLeft20}>
                <Text style={styles.titleBar}>QTDE</Text>
              </View>
              <View style={styles.columnAlignLeft20}>
                <Text style={styles.titleBar}>UNITÁRIO</Text>
              </View>
              <View style={styles.columnAlignLeft20}>
                <Text style={styles.titleBar}>TOTAL</Text>
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
        <View style={styles.section}>
          {pedidoAImprimir.length > 0 ? (
            pedidoAImprimir.map((prod, index) => {
              return (
                <View style={styles.rowH8mm} key={index}>
                  <View style={styles.columnNoAlign30}>
                    <Text style={styles.contentText}>
                      {prod.codigo_produto}
                    </Text>
                  </View>

                  <View style={styles.column110}>
                    <Text style={styles.contentText}>
                      {prod.descricao_produto}
                    </Text>
                  </View>
                  <View style={styles.columnAlignLeft20}>
                    <Text style={styles.contentText}>{prod.qtde}</Text>
                  </View>
                  <View style={styles.columnAlignLeft20}>
                    <Text style={styles.contentText}>
                      {prod.valor.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Text>
                  </View>
                  <View style={styles.columnAlignLeft20}>
                    <Text style={styles.contentText}>
                      {prod.total.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Text>
                  </View>
                </View>
              );
            })
          ) : (
            <></>
          )}
        </View>
        {pedidoAImprimir.length > 0 ? (
          <View style={styles.subSection}>
            <View style={styles.rowSubSection}>
              <View style={styles.columnNoAlign40}>
                <Text style={styles.titleBar}>TOTAL PEDIDO:</Text>
              </View>
              <View style={styles.columnNoAlign20}>
                <Text style={styles.contentText}>
                  {totalPedido
                    ? pedidoAImprimir[0].geral.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : '0'}
                </Text>
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}
        <View style={styles.section}>
          <View style={styles.rowH40mm}>
            <Text style={styles.titleSection}>
              Observações:{' '}
              {pedidoAImprimir.length > 0 && pedidoAImprimir[0].obs
                ? pedidoAImprimir[0].obs
                : ''}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
