import React, { useRef, useState, memo } from "react";
import { Box, FormControl, IconButton, TextField } from "@mui/material";

import { AddCircleTwoTone, PhotoCamera } from "@mui/icons-material";
import MainCard from "../../../../../../components/Cards/MainCard";
import { toast } from "react-toastify";

export const ProdutoIndividual = memo(
  ({ produto, adicionaItem, abrirVisualizadorImagem, podeAlterarPreco }) => {
    const valorRef = useRef("valorRef");
    const [qtde, setQtde] = useState(0);
    const [valor, setValor] = useState(produto.venda1);
    const [error, setError] = useState(false);

    const aoAdicionarItem = () => {
      if (valor < produto.vl_minimo) {
        toast.error(
          "O valor do produto deve ser maior ou igual ao valor minimo sugerido! "
        );
        valorRef.current.focus();
        setError(true);
      } else {
        setError(false);
        adicionaItem(produto, qtde, valor);
        setQtde(0);
      }
    };

    return (
      <Box
        sx={{
          mb: 0.1,
          mt: 0.1,
          p: 0.25,
          display: "flex",

          justifyContent: "space-around",
        }}
      >
        <MainCard title={produto.descricao_produto}>
          <FormControl sx={{ width: "25%", mr: 2, p: 0 }}>
            <TextField
              label="QTDE"
              size="small"
              sx={{ bgcolor: "primary.light" }}
              value={qtde}
              type="number"
              onChange={(e) => setQtde(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ width: "25%", mr: 2, p: 0 }}>
            <TextField
              sx={{ bgcolor: "primary.light" }}
              label="Valor"
              size="small"
              value={valor}
              type="number"
              disabled={podeAlterarPreco > 0 ? false : true}
              onChange={(e) => setValor(e.target.value)}
              inputRef={valorRef}
              error={error}
            />
          </FormControl>
          <IconButton onClick={() => aoAdicionarItem()}>
            <AddCircleTwoTone sx={{ color: "primary.success" }} />
          </IconButton>
          {produto.foto ? (
            <IconButton
              onClick={() => abrirVisualizadorImagem(produto.codigo_produto)}
            >
              <PhotoCamera />
            </IconButton>
          ) : (
            <></>
          )}
          {produto.ultimo ? (
            <Box sx={{ width: "100%", mt: 2 }}>
              <strong>Última Compra: </strong>
              {produto.ultimo}
            </Box>
          ) : (
            <></>
          )}
        </MainCard>
      </Box>
    );
  }
);
