import { FormControl, TextField, InputAdornment } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';

const PesquisaComponente = ({ efetuaPesquisa, textoPesquisa }) => {
  return (
    <FormControl sx={{ m: 1, mt: 0.3 }}>
      <TextField
        value={textoPesquisa}
        onChange={(e) => efetuaPesquisa(e)}
        id="searchBox"
        placeholder="Pesquise o produto..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
        size="small"
        variant="outlined"
      />
    </FormControl>
  );
};
export default PesquisaComponente;
