import React, { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material';
import { usePDF, Font } from '@react-pdf/renderer';
import FileViewer from 'react-file-viewer';
import { LayoutPdfImprimir } from './LayoutPdfImprimir/LayoutPdfImprimir';
import Arial from '../../../../fonts/arial.ttf';
import ArialBd from '../../../../fonts/arialbd.ttf';
import { CloseTwoTone, PrintTwoTone } from '@mui/icons-material';
import { styles } from './LayoutPdfImprimir/styles';

export const PdfImprimePedido = ({
  open,
  fecharModalImprimePedido,
  pedidoAImprimir,
  totalPedido,
}) => {
  const [instance, update] = usePDF({
    document: LayoutPdfImprimir(pedidoAImprimir, totalPedido),
  });

  const type = 'pdf';
  useEffect(() => {
    update();
  }, [totalPedido]);




  useEffect(() => {
    Font.register({
      family: 'Arial',
      fonts: [
        {
          src: Arial,
          format: 'truetype',
        },
        {
          src: ArialBd,
          fontWeight: 'bold',
          format: 'truetype',
        },
      ],
    });
  }, []);

  if (instance.loading) {
    return (
      <Stack direction="row" spacing={2}>
        <Typography variant="h6">Gerando PDF...</Typography>{' '}
      </Stack>
    );
  }

  if (instance.error)
    return <div>Erro ao Carregar documento {instance.error}</div>;

  const onError = (e) => {
    console.log(e, 'error in file-viewer');
  };

  return (
    <Modal
      open={open}
      onClose={fecharModalImprimePedido}
      aria-labelledby="Cadastro de Clientes"
      aria-describedby="Cadastro de Clientes"
      hideBackdrop
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
          height: {
            xs: '95%',
            sm: '95%',
            md: '95%',
            lg: '95%',
            xl: '95%',
            xxl: '95%',
          },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            marginBottom: '5%',
          }}
        >
          <Typography variant="h6">Impressão de Pedido</Typography>{' '}
          <Box>
            <IconButton
              href={instance.url}
              download={`pedido${pedidoAImprimir[0].codigo_venda}.pdf`}
            >
              <PrintTwoTone />
            </IconButton>
            <IconButton
              onClick={() => {
                fecharModalImprimePedido();
              }}
            >
              <CloseTwoTone sx={{ color: 'primary.error' }} />
            </IconButton>
          </Box>
        </Box>

        {instance.url ? (
          <FileViewer
            className={styles.fileView}
            fileType={type}
            filePath={instance.url ? instance.url : ''}
            onError={onError}
          />
        ) : (
          <></>
        )}
      </Box>
    </Modal>
  );
};
