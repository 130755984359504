import React from 'react';
import { TableCell, TableRow, IconButton } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

export default function Row({ dados, modalCadastroClientes }) {
  return (
    <TableRow>
      <TableCell colSpan={1} sx={{ margin: 0, padding: 0 }}>
        <IconButton onClick={() => modalCadastroClientes(dados)}>
          <PreviewIcon />
        </IconButton>
      </TableCell>

      <TableCell>{dados.nome_fantasia}</TableCell>
      <TableCell>{dados.razao_social}</TableCell>
    </TableRow>
  );
}
