import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';
import { CadastraVisita } from './CadastraVisita/CadastraVisita';
import { ReagendaVisita } from './ReagendaVisita/ReagendaVisita';
import { ConfirmaVisita } from './ConfirmaVisita/ConfirmaVisita';
import { CancelaVisita } from './CancelaVisita/CancelaVisita';

export default function TabelaResultados({
  lista,
  listaClientes,
  abrirModalCadastroVisitas,
  abrirModalReagendaVisitas,
  abrirModalCancelaVisitas,
  abrirModalConfirmaVisitas,
  fecharModalConfirmaVisitas,
  fecharModalCancelaVisitas,
  fecharModalReagendaVisitas,
  modalReagendaVisitas,
  modalCancelaVisitas,
  modalConfirmaVisitas,
  fecharModalCadastroVisitas,
  modalCadastroVisitas,
  agendaVisita,
  confirmaVisita,
  reagendaVisita,
  cancelaVisita,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [visitaSelecionada, setVisitaSelecionada] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
  /*----------------------------------------*/
  const aoAbrirModalReagendaVisitas = (codigoVisita) => {
    setVisitaSelecionada(codigoVisita);
    abrirModalReagendaVisitas();
  };
  const aoFecharModalReagendaVisitas = () => {
    setVisitaSelecionada('');
    fecharModalReagendaVisitas();
  };
  /*----------------------------------------*/
  const aoAbrirModalCancelaVisitas = (codigoVisita) => {
    setVisitaSelecionada(codigoVisita);
    abrirModalCancelaVisitas();
  };
  const aoFecharModalCancelaVisitas = () => {
    setVisitaSelecionada('');
    fecharModalCancelaVisitas();
  };
  /*----------------------------------------*/
  const aoAbrirModalConfirmaVisitas = (codigoVisita) => {
    setVisitaSelecionada(codigoVisita);
    abrirModalConfirmaVisitas();
  };
  const aoFecharModalConfirmaVisitas = () => {
    setVisitaSelecionada('');
    fecharModalConfirmaVisitas();
  };
  /*----------------------------------------*/

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <CadastraVisita
        open={modalCadastroVisitas}
        onClose={fecharModalCadastroVisitas}
        listaClientes={listaClientes}
        agendaVisita={agendaVisita}
      />

      <ReagendaVisita
        open={modalReagendaVisitas}
        onClose={aoFecharModalReagendaVisitas}
        reagendaVisita={reagendaVisita}
        visitaSelecionada={visitaSelecionada}
      />

      <ConfirmaVisita
        open={modalConfirmaVisitas}
        onClose={aoFecharModalConfirmaVisitas}
        visitaSelecionada={visitaSelecionada}
        confirmaVisita={confirmaVisita}
      />

      <CancelaVisita
        open={modalCancelaVisitas}
        onClose={aoFecharModalCancelaVisitas}
        visitaSelecionada={visitaSelecionada}
        cancelaVisita={cancelaVisita}
      />

      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ margin: 0, padding: 0.5 }}></TableCell>
            <TableCell sx={{ margin: 0, padding: 0.5 }}>Data</TableCell>
            <TableCell sx={{ margin: 0, padding: 0.5 }}>Cliente</TableCell>
            <TableCell sx={{ margin: 0, padding: 0.5 }}>Solicitação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista ? (
            lista.length > 0 ? (
              lista
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((dado, index) => {
                  return (
                    <Row
                      dados={dado}
                      key={index}
                      abrirModalCadastroVisitas={abrirModalCadastroVisitas}
                      aoAbrirModalReagendaVisitas={aoAbrirModalReagendaVisitas}
                      aoAbrirModalCancelaVisitas={aoAbrirModalCancelaVisitas}
                      aoAbrirModalConfirmaVisitas={aoAbrirModalConfirmaVisitas}
                    />
                  );
                })
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={lista.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
