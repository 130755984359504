import React, { useEffect } from 'react';

import { ConfirmProvider } from 'material-ui-confirm';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from './contexts/AuthContext';
import { GlobalStates } from './contexts/GlobalStates';

import SubApp from './SubApp';

function App() {
  return (
    <AuthProvider>
      <ConfirmProvider>
        <GlobalStates>
          <SubApp />
        </GlobalStates>
      </ConfirmProvider>
    </AuthProvider>
  );
}

export default App;
