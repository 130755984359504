import React from 'react';
import { TableCell, TableRow, IconButton } from '@mui/material';

import { CancelOutlined, CheckCircle, EventRepeat } from '@mui/icons-material';
import moment from 'moment';
export default function Row({
  dados,
  aoAbrirModalReagendaVisitas,
  aoAbrirModalCancelaVisitas,
  aoAbrirModalConfirmaVisitas,
}) {
  return (
    <TableRow
      sx={
        dados.vis_status.toLowerCase() === 'pendente'
          ? { bgcolor: 'primary.light' }
          : dados.vis_status === 'cancelada'
          ? { bgcolor: 'primary.softRed' }
          : ''
      }
    >
      <TableCell sx={{ margin: 0, padding: 0 }}>
        <IconButton
          onClick={() => aoAbrirModalCancelaVisitas(dados.vis_codigo)}
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.buttonColorOther',
            margin: 1,
          }}
        >
          <CancelOutlined />
        </IconButton>

        <IconButton
          onClick={() => {
            aoAbrirModalConfirmaVisitas(dados.vis_codigo);
          }}
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.buttonColorOther',
            margin: 1,
          }}
        >
          <CheckCircle />
        </IconButton>
        <IconButton
          onClick={() => aoAbrirModalReagendaVisitas(dados.vis_codigo)}
          sx={{ bgcolor: 'primary.main', color: 'primary.error', margin: 1 }}
        >
          <EventRepeat />
        </IconButton>
      </TableCell>

      <TableCell sx={{ margin: 0, padding: 0.5 }}>
        {moment(dados.vis_data).add(3, 'h').format('DD/MM/YY')}
      </TableCell>

      <TableCell sx={{ margin: 0, padding: 0.5 }}>
        {dados.nome_fantasia}
      </TableCell>
      <TableCell sx={{ margin: 0, padding: 0.5 }}>{dados.vis_obs}</TableCell>
    </TableRow>
  );
}
