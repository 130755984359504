import React from 'react';
import { TableCell, TableRow, IconButton } from '@mui/material';

import { DeleteForever, PrintTwoTone } from '@mui/icons-material';
import moment from 'moment';
import { toast } from 'react-toastify';
export default function Row({
  dados,
  abrirModalImprimePedido,
  abrirModalCadastroPedidos,
  apagaPedido,
}) {
  const aoAbrirModalCadastroPedidos = (dados) => {
    if (dados.codigo_venda_sv !== 0) {
      toast.error(
        'Operação não permitida - Pedido  não permite edição pois está em processo de faturamento! '
      );
    } else {
      abrirModalCadastroPedidos(dados);
    }
  };
  return (
    <TableRow
      sx={
        dados.codigo_venda_sv !== 0
          ? { bgcolor: 'primary.softGreen' }
          : { bgcolor: 'none' }
      }
    >
      <TableCell sx={{ margin: 0, padding: 0 }}>
        <IconButton
          onClick={() => abrirModalImprimePedido(dados)}
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.buttonColorOther',
            margin: 1,
          }}
        >
          <PrintTwoTone />
        </IconButton>
        {dados.codigo_venda_sv !== 0 ? (
          <></>
        ) : (
          <IconButton
            onClick={() =>
              apagaPedido(dados.codigo_venda, dados.codigo_venda_sv)
            }
            sx={{ bgcolor: 'primary.main', color: 'primary.error', margin: 1 }}
          >
            <DeleteForever />
          </IconButton>
        )}
      </TableCell>
      <TableCell
        sx={{ margin: 0, padding: 0.5 }}
        onClick={() => aoAbrirModalCadastroPedidos(dados)}
      >
        {dados.razao_social}
      </TableCell>
      <TableCell sx={{ margin: 0, padding: 0.5 }}>
        {moment(dados.emissao).add(3, 'h').format('DD/MM/YY')}
      </TableCell>
      <TableCell sx={{ margin: 0, padding: 0.5 }}>
        <strong>
          {dados.total.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </strong>
      </TableCell>
    </TableRow>
  );
}
