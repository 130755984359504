import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AddCircle, SearchRounded } from "@mui/icons-material";
import api from "../../api/api";
import { useAuthState } from "../../contexts/AuthContext";
import TabelaResultados from "./TabelaResultados/TabelaResultados";
import Spinner from "../../components/Spinner/Spinner";
import TitlePage from "../../components/TitlePage/TitlePage";
import { useCallback } from "react";

export default function Clientes() {
  const [loading, setLoading] = useState(false);
  const [listaClientes, setListaClientes] = useState([]);
  const { usuarioIDLogado, empresaAtiva } = useAuthState();
  const [clientesFiltrados, setClientesFiltrados] = useState([]);
  const [modalCadastroClientes, setModalCadastroClientes] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);
  const [recarregaClientes, setRecarregaClientes] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const abrirModalCadastroClientes = (dados) => {
    setClienteSelecionado(dados);
    setModalCadastroClientes(true);
  };
  const fecharModalCadastroClientes = () => {
    setModalCadastroClientes(false);
    setRecarregaClientes(!recarregaClientes);
  };

  useEffect(() => {
    setLoading(true);
    api
      .post(
        "/clientes/consulta",
        { codigo: usuarioIDLogado },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setListaClientes(data);
        setClientesFiltrados(data);
      })
      .catch((err) => {
        toast.error("Erro ao Carregar Clientes" + err.response.data);
        console.log(err.response.data);
      })
      .finally(() => setLoading(false));
  }, [usuarioIDLogado, empresaAtiva, recarregaClientes]);

  const filtraClientes = useCallback(
    (parametro) => {
      const clienteEncontrado = listaClientes.filter(
        (cliente) =>
          cliente.cidade.toLowerCase().includes(parametro.toLowerCase()) ||
          cliente.nome_fantasia
            .toLowerCase()
            .includes(parametro.toLowerCase()) ||
          cliente.razao_social.toLowerCase().includes(parametro.toLowerCase())
      );
      setClientesFiltrados(clienteEncontrado);
    },
    [listaClientes]
  );

  const inserirCliente = (cliente) => {
    api
      .post("/clientes/inserir", cliente, {
        headers: { banco: empresaAtiva },
      })
      .then((res) => {
        toast.success("Cliente inserido com sucesso");
        fecharModalCadastroClientes();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response.data);
      });
  };

  const alterarCliente = (cliente) => {
    console.log(cliente);
    api
      .post("/clientes/alterar", cliente, {
        headers: { banco: empresaAtiva },
      })
      .then((res) => {
        toast.success("Cliente alterado com sucesso");
        fecharModalCadastroClientes();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        console.log(err.response.data);
      });
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        width: "100%",
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "fixed",
          flexWrap: "wrap",
          top: 75,
          zIndex: 9,
          height: "15vh",
          width: {
            xs: "75%",
            sm: "90%",
            md: "90%",
            lg: "90%",
            xl: "95%",
          },
          maxWidth: "100%",
          bgcolor: "background.paper",
        }}
      >
        <TitlePage title={"Clientes"} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1%",
            marginBottom: "5%",
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
            padding: "1%",
            borderBottom: "1px solid",
          }}
        >
          {!matches ? (
            <Tooltip title="Adidionar um cliente">
              <IconButton onClick={() => abrirModalCadastroClientes([])}>
                <AddCircle sx={{ color: "primary.success" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Adidionar um cliente">
              <Button
                variant="contained"
                size="small"
                startIcon={<AddCircle sx={{ color: "primary.success" }} />}
                onClick={() => abrirModalCadastroClientes([])}
              >
                Novo Cliente
              </Button>
            </Tooltip>
          )}
          <FormControl
            sx={{ width: "85%", borderRadius: "5%", marginLeft: "5%" }}
          >
            <TextField
              onChange={(e) => filtraClientes(e.target.value.toLowerCase())}
              id="searchBox"
              placeholder="Pesquise o cliente..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
              size="small"
              variant="outlined"
            />
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          zIndex: "-1",
          marginTop: {
            xs: "15vh",
            sm: "16vh",
            md: "19vh",
            lg: "21vh",
            xl: "24vh",
            xxl: "40vh",
          },
        }}
      >
        <TabelaResultados
          loading={loading}
          lista={clientesFiltrados}
          modalCadastroClientes={modalCadastroClientes}
          fecharModalCadastroClientes={fecharModalCadastroClientes}
          abrirModalCadastroClientes={abrirModalCadastroClientes}
          clienteSelecionado={clienteSelecionado}
          inserirCliente={inserirCliente}
          alterarCliente={alterarCliente}
        />
      </Box>
    </Box>
  );
}
