import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  NativeSelect,
  TextField,
  Typography,
} from '@mui/material';

import { CloseTwoTone, RemoveCircle } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useAuthState } from '../../../../contexts/AuthContext';

import VisualizadorImagem from './VisualizadorImagem/VisualizadorImagem';
import Spinner from '../../../../components/Spinner/Spinner';
import PesquisaComponente from './PesquisaComponente/PesquisaComponente';
import ListaProdutosFiltradosVirtualizada from './ListaProdutosFiltradorVirtualizada/ListaProdutosFiltradosVirtualizada';

export const CadastroPedidos = ({
  open,
  onClose,
  loadingPedido,
  listaClientes,
  listaProdutos,
  pedidoSelecionado,
  buscaProdutos,
  salvarPedido,
  abrirVisualizadorImagem,
  fecharVisualizadorImagem,
  imagemSelecionada,
  visualizadorImagem,
  loadingImagem,
  nivelAcesso,
}) => {
  const { usuarioIDLogado } = useAuthState();
  const [textoPesquisa, setTextoPesquisa] = useState('');
  const [clienteSelecionado, setClienteSelecionado] = useState(0);
  const [itens, setItens] = useState([]);
  const [redefine, setRedefine] = useState(false);
  const [totalPedido, setTotalPedido] = useState(0);
  const [observacao, setObservacao] = useState('');
  const [formaPagto, setFormaPagto] = useState('');
  const podeAlterarPreco = nivelAcesso
    .filter((item) => item.grupo === 'PEDIDOS')
    .map((item) =>
      item.liberados.findIndex(
        (liberado) => liberado.acesso === 'ALTERAR PRECO'
      )
    );

  useEffect(() => {
    if (pedidoSelecionado.length > 0) {
      setClienteSelecionado(pedidoSelecionado[0].codigo_cliente);
      buscaProdutos(parseInt(pedidoSelecionado[0].codigo_cliente));
      setObservacao(pedidoSelecionado[0].obs);
      setFormaPagto(pedidoSelecionado[0].formaPagto);
      setItens(
        pedidoSelecionado.map((item) => {
          return {
            codigo_cliente: item.codigo_cliente,
            codigo_produto: item.codigo_produto,
            codigo_vendedor: usuarioIDLogado,
            descricao_produto: item.descricao_produto,
            valor: item.valor,
            qtde: item.qtde,
            total: item.total,
            tipo: 'TROCA',
            status: 'PEDIDO',
            transmitido: 'SIM',
            desconto: 0,
            obs: item.obs,
            formaPagto: item.formaPagto,
            codigo_venda_sv: 0,
          };
        })
      );
    }
  }, [pedidoSelecionado]);

  useEffect(() => {
    const somaTotal = () => {
      const initialValue = 0;
      const soma = itens.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total,
        initialValue
      );

      setTotalPedido(soma);
    };
    somaTotal();
  }, [itens]);

  function adicionaItem(produto, qtde, valor) {
    const existeProduto = itens.findIndex(
      (item) => item.codigo_produto === produto.codigo_produto
    );
    if (qtde <= 0) {
      toast.error('A quantidade deve ser maior que 0');
    } else if (existeProduto !== -1) {
      toast.error('Produto já selecionado para o pedido');
    } else {
      setItens((prevItens) => [
        ...prevItens,
        {
          codigo_produto: produto.codigo_produto,
          codigo_cliente: clienteSelecionado,
          codigo_vendedor: usuarioIDLogado,
          qtde: qtde,
          valor: parseFloat(valor),
          tipo: 'TROCA',
          status: 'PEDIDO',
          total: parseFloat(qtde * valor),
          descricao_produto: produto.descricao_produto,
          desconto: 0,
          obs: observacao,
          transmitido: 'SIM',
          codigo_venda_sv: pedidoSelecionado.codigo_venda
            ? pedidoSelecionado.codigo_venda
            : '',
        },
      ]);
    }
    setTextoPesquisa('');
    setRedefine(true);
  }
  function removeItem(produto) {
    const indice = itens.findIndex(
      (item) => item.codigo_produto === produto.codigo_produto
    );
    const newItens = [...itens.slice(0, indice), ...itens.slice(indice + 1)];
    setItens(newItens);
  }

  const selecionaCliente = (e) => {
    setRedefine(false);
    buscaProdutos(parseInt(e.target.value));
    setClienteSelecionado(e.target.value);
  };

  let produtosFiltrados = useMemo(() => {
    if (textoPesquisa === '') {
      return [];
    }
    return listaProdutos.filter((produto) => {
      return produto.descricao_produto
        .toLowerCase()
        .includes(textoPesquisa.toLowerCase());
    });
  }, [listaProdutos, textoPesquisa, redefine]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const efetuaPesquisa = (event) => {
    setTextoPesquisa(event.target.value);
    const value = event.target.value;
    const delayedSearch = debounce(setTextoPesquisa, 150); // Defina o atraso desejado (300ms)
    delayedSearch(value);
  };

  const fechModalCadastroPedidos = () => {
    setRedefine(true);
    setClienteSelecionado(0);
    setItens([]);
    setTextoPesquisa('');
    setObservacao('');
    onClose();
  };

  if (loadingPedido) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="Cadastro de Pedidos"
        aria-describedby="Cadastro de Pedidos"
        hideBackdrop
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
            height: {
              xs: '95%',
              sm: '95%',
              md: '95%',
              lg: '95%',
              xl: '95%',
              xxl: '95%',
            },
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 1,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <Spinner />
        </Box>
      </Modal>
    );
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Cadastro de Pedidos"
      aria-describedby="Cadastro de Pedidos"
      hideBackdrop
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '95%', md: '95%', lg: '50%', xl: '40%' },
          height: {
            xs: '95%',
            sm: '95%',
            md: '95%',
            lg: '95%',
            xl: '95%',
            xxl: '95%',
          },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 1,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <VisualizadorImagem
          open={visualizadorImagem}
          handleClose={fecharVisualizadorImagem}
          imagemSelecionada={imagemSelecionada}
          loadingImagem={loadingImagem}
        />

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            marginBottom: '5%',
          }}
        >
          <Typography variant="h6">Cadastro de Trocas</Typography>{' '}
          <Box>
            <IconButton
              onClick={() => {
                fechModalCadastroPedidos();
              }}
            >
              <CloseTwoTone sx={{ color: 'primary.error' }} />
            </IconButton>
          </Box>
        </Box>
        <FormControl sx={{ m: 1, mt: 0.3 }}>
          <NativeSelect
            id="selectCliente"
            value={clienteSelecionado}
            onChange={selecionaCliente}
            disabled={clienteSelecionado ? true : false}
            size="small"
          >
            <option value={0}>Selecione um Cliente</option>
            {listaClientes.map((cliente, index) => (
              <option
                key={cliente.codigo_cliente}
                value={cliente.codigo_cliente}
              >
                {cliente.nome_fantasia
                  ? cliente.nome_fantasia
                  : cliente.razao_social}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        {clienteSelecionado === 0 ? (
          <></>
        ) : (
          <>
            <FormControl sx={{ m: 1, mt: 0.3 }}>
              <TextField
                value={observacao}
                onChange={(e) => setObservacao(e.target.value.toUpperCase())}
                id="campoObs"
                size="small"
                placeholder="Obervação"
              />
            </FormControl>
            <FormControl sx={{ m: 1, mt: 0.3 }}>
              <TextField
                value={formaPagto}
                onChange={(e) => setFormaPagto(e.target.value.toUpperCase())}
                id="campoPgto"
                size="small"
                placeholder="Condição de pagamento"
              />
            </FormControl>
            <PesquisaComponente
              efetuaPesquisa={efetuaPesquisa}
              textoPesquisa={textoPesquisa}
            />
            {produtosFiltrados.length > 0 ? (
              <ListaProdutosFiltradosVirtualizada
                produtos={produtosFiltrados}
                podeAlterarPreco={podeAlterarPreco}
                abrirVisualizadorImagem={abrirVisualizadorImagem}
                adicionaItem={adicionaItem}
              />
            ) : (
              <></>
            )}

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '1px solid',
                marginBottom: '5%',
                borderTop: '1px solid',
                marginTop: '5%',
              }}
            >
              <Typography variant="h6">Produtos Selecionados</Typography>{' '}
            </Box>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {itens ? (
                itens.map((item, index) => {
                  return (
                    <ListItem
                      key={item.codigo_produto}
                      divider
                      dense
                      disableGutters
                    >
                      <IconButton onClick={() => removeItem(item)}>
                        <RemoveCircle sx={{ color: 'primary.error' }} />
                      </IconButton>

                      <ListItemText
                        sx={{ width: '45%', maxWidth: '45%' }}
                        primary=""
                        secondary={item.descricao_produto}
                      />
                      <ListItemText
                        primary="QTDE."
                        secondary={item.qtde ? item.qtde : 0}
                      />
                      <ListItemText primary="UNIT." secondary={item.valor} />
                      <ListItemText
                        primary="TOTAL"
                        secondary={parseFloat(item.valor * item.qtde).toFixed(
                          2
                        )}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <></>
              )}
            </List>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: '5%',
                marginBottom: '5%   ',
              }}
            >
              <Typography variant="h6">
                Total do Pedido{' '}
                {totalPedido.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Box>
            <Button
              onClick={() =>
                salvarPedido({
                  obs: observacao,
                  formaPagto: formaPagto,
                  codigo_venda:
                    pedidoSelecionado.length > 0
                      ? pedidoSelecionado[0].codigo_venda
                        ? pedidoSelecionado[0].codigo_venda
                        : 0
                      : 0,
                  itens: [...itens],
                })
              }
              variant="contained"
            >
              Salvar
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};
