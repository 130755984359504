import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';

import { PdfImprimePedido } from './PdfImprimePedido/PdfImprimePedido';
import { CadastroPedidos } from './CadastroPedidos/CadastroPedidos';

export default function TabelaResultados({
  lista,
  loadingPedido,
  pedidoSelecionado,
  abrirModalImprimePedido,
  fecharModalImprimePedido,
  modalImprimePedido,
  modalCadastroPedidos,
  abrirModalCadastroPedidos,
  fecharModalCadastroPedidos,
  abrirVisualizadorImagem,
  fecharVisualizadorImagem,
  visualizadorImagem,
  imagemSelecionada,
  listaClientes,
  listaProdutos,
  totalPedido,
  apagaPedido,
  buscaProdutos,
  salvarPedido,
  loadingImagem,
  nivelAcesso,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      {pedidoSelecionado.length > 0 ? (
        <PdfImprimePedido
          pedidoAImprimir={pedidoSelecionado}
          totalPedido={totalPedido}
          open={modalImprimePedido}
          fecharModalImprimePedido={fecharModalImprimePedido}
        />
      ) : (
        <></>
      )}
      {listaClientes.length > 0 ? (
        <CadastroPedidos
          open={modalCadastroPedidos}
          onClose={fecharModalCadastroPedidos}
          listaClientes={listaClientes}
          listaProdutos={listaProdutos}
          pedidoSelecionado={pedidoSelecionado}
          buscaProdutos={buscaProdutos}
          salvarPedido={salvarPedido}
          abrirVisualizadorImagem={abrirVisualizadorImagem}
          fecharVisualizadorImagem={fecharVisualizadorImagem}
          imagemSelecionada={imagemSelecionada}
          visualizadorImagem={visualizadorImagem}
          loadingImagem={loadingImagem}
          loadingPedido={loadingPedido}
          nivelAcesso={nivelAcesso}
        />
      ) : (
        <></>
      )}
      <Table stickyHeader sx={{ margin: 0, padding: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ margin: 0, padding: 0 }}></TableCell>
            <TableCell sx={{ margin: 0, padding: 0.3 }}>Nome</TableCell>
            <TableCell sx={{ margin: 0, padding: 0.3 }}>Emissão</TableCell>
            <TableCell sx={{ margin: 0, padding: 0.3 }}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista ? (
            lista.length > 0 ? (
              lista
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((dado, index) => {
                  return (
                    <Row
                      dados={dado}
                      key={dado.codigo_venda}
                      abrirModalImprimePedido={abrirModalImprimePedido}
                      apagaPedido={apagaPedido}
                      abrirModalCadastroPedidos={abrirModalCadastroPedidos}
                    />
                  );
                })
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={lista.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
