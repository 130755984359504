import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: { width: '100%', display: 'flex', flexDirection: 'column' },
  fileView: { width: '100%' },
  row: { display: 'flex', flexDirection: 'row' },
  page: {
    margin: '1mm',
    backgroundColor: '#ffffff',
    fontSize: '10pt',
    fontFamily: 'Arial',
  },
  header: {
    height: '45mm',
    width: '208mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    border: '0.2mm solid #565656',
    borderRadius: '5mm',
  },

  logoImage: {
    width: '45mm',
    height: '20mm',
    maxWidth: '45mm',
    padding: '2mm',
  },

  titleDocument: {
    display: 'flex',
    width: '160mm',
    justifyContent: 'center',
    alignItems: 'center',
  },

  section: {
    display: 'flex',
    width: '208mm',
    marginTop: '3mm',
    border: '0.2mm solid #565656',
    borderRadius: '5mm',
    padding: '1mm',
  },
  subSection: {
    display: 'flex',
    marginLeft: '128mm',
    width: '80mm',
    marginTop: '5mm',
    border: '0.2mm solid #565656',
    borderRadius: '5mm',
    padding: '1mm',
  },
  rowH10mm: {
    margin: '2mm',
    height: '10mm',
    padding: '1mm',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowH50mm: {
    margin: '2mm',
    height: '50mm',
    padding: '1mm',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowH40mm: {
    margin: '2mm',
    height: '40mm',
    padding: '1mm',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  rowH8mm: {
    height: '8mm',
    display: 'flex',
    width: '100%',
    paddingLeft: '1mm',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowSubSection: {
    height: '8mm',
    display: 'flex',
    width: '100%',
    paddingRight: '1mm',
    borderTop: 'solid 0.2mm #565656',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  column10: {
    display: 'flex',
    width: '10mm',
    flexDirection: 'column',
    padding: '1mm',
    alignItems: 'center',
  },
  column30: {
    display: 'flex',
    width: '30mm',
    flexDirection: 'column',
    padding: '1mm',
    alignItems: 'center',
  },
  columnNoAlign30: {
    display: 'flex',
    width: '30mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  columnAlignLeft30: {
    display: 'flex',
    width: '30mm',
    flexDirection: 'column',
    padding: '1mm',
    alignItems: 'flex-end',
  },
  columnAlignLeft20: {
    display: 'flex',
    width: '20mm',
    flexDirection: 'column',
    padding: '1mm',
    alignItems: 'flex-end',
  },

  column20: {
    display: 'flex',
    width: '20mm',
    flexDirection: 'column',
    padding: '1mm',
    alignItems: 'center',
  },
  columnNoAlign20: {
    display: 'flex',
    width: '20mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  column40: {
    display: 'flex',
    width: '40mm',
    flexDirection: 'column',
    padding: '1mm',
    alignItems: 'center',
  },
  column50: {
    display: 'flex',
    width: '50mm',
    flexDirection: 'column',
    padding: '1mm',
    alignItems: 'center',
  },
  columnNoAlign40: {
    display: 'flex',
    width: '40mm',
    flexDirection: 'column',
    padding: '1mm',
  },

  column60: {
    display: 'flex',
    width: '60mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  column70: {
    display: 'flex',
    width: '70mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  column90: {
    display: 'flex',
    width: '90mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  column110: {
    display: 'flex',
    width: '110mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  column120: {
    display: 'flex',
    width: '130mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  column150: {
    display: 'flex',
    width: '150mm',
    flexDirection: 'column',
    padding: '1mm',
  },
  titleText: { fontSize: '25pt', fontWeight: 'bold' },

  titleSection: {
    fontWeight: 600,
    fontSize: '16pt',
  },
  textTitleColumn: {
    fontWeight: 'bold',
    fontSize: '11pt',
  },
  textContentColumn: {
    fontSize: '9pt',
  },

  titleBar: { fontSize: '10pt', fontWeight: 800 },
  contentText: { fontSize: '9pt', marginBottom: '2mm' },
});
