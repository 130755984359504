import React from 'react';

import {
  CellMeasurer,
  CellMeasurerCache,
  List,
  AutoSizer,
} from 'react-virtualized';

import { ProdutoIndividual } from './ProdutoIndividual/ProdutoIndividual';
const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 60,
});
const ListaProdutosFiltradosVirtualizada = ({
  produtos,
  podeAlterarPreco,
  abrirVisualizadorImagem,
  adicionaItem,
}) => {
  const rowRenderer = ({ index, key, parent, style }) => {
    const produto = produtos[index];

    return (
      <CellMeasurer
        cache={cache}
        parent={parent}
        key={key}
        rowIndex={index}
        minHeight={600}
      >
        <div style={style}>
          <ProdutoIndividual
            produto={produto}
            abrirVisualizadorImagem={abrirVisualizadorImagem}
            adicionaItem={adicionaItem}
            key={produtos[index].codigo_produto}
            podeAlterarPreco={podeAlterarPreco}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <div style={{ minHeight: 400, height: 600, width: '100%' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            rowCount={produtos.length}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            width={width} // Defina a largura adequada
            height={height} // Defina a altura adequada
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ListaProdutosFiltradosVirtualizada;
