import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';

import Row from './Rows/Row';
import Spinner from '../../../components/Spinner/Spinner';
import ModalClientes from './ModalClientes/ModalClientes';

export default function TabelaResultados({
  lista,
  loading,
  modalCadastroClientes,
  fecharModalCadastroClientes,
  clienteSelecionado,
  abrirModalCadastroClientes,
  inserirCliente,
  alterarCliente,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box>
      <ModalClientes
        open={modalCadastroClientes}
        onClose={fecharModalCadastroClientes}
        clienteSelecionado={clienteSelecionado}
        inserirCliente={inserirCliente}
        alterarCliente={alterarCliente}
      />
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell colSpan={1}></TableCell>
            <TableCell>N.Fantasia</TableCell>
            <TableCell>R. Social</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista ? (
            lista.length > 0 ? (
              lista
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((dado, index) => {
                  return (
                    <Row
                      dados={dado}
                      key={index}
                      modalCadastroClientes={abrirModalCadastroClientes}
                    />
                  );
                })
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={lista.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
