import React from 'react';
import { Link } from 'react-router-dom';
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  useTheme,
  Button,
} from '@mui/material';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PeopleIcon from '@mui/icons-material/People';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArticleIcon from '@mui/icons-material/Article';

import {
  useAuthState,
  handleLogout,
  useAuthDispatch,
} from '../../../contexts/AuthContext';

function ItemListDrawer() {
  const { url, acessos } = useAuthState();

  const dispatch = useAuthDispatch();
  const theme = useTheme();

  const acessaClientes = acessos
    ? acessos.findIndex((acesso) => acesso.grupo === 'CLIENTES')
    : '';
  const acessaComissao = acessos
    ? acessos.findIndex((acesso) => acesso.grupo === 'COMISSAO')
    : '';
  const acessaPedidos = acessos
    ? acessos.findIndex((acesso) => acesso.grupo === 'PEDIDOS')
    : '';
  const acessaVisitas = acessos
    ? acessos.findIndex((acesso) => acesso.grupo === 'VISITAS')
    : '';
  return (
    <List>
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/">
        <Tooltip arrow title="Home" placement="right">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Home" />
      </ListItem>
      {/*-------------------------------------------------------------*/}
      {acessaClientes !== -1 ? (
        <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/clientes">
          <Tooltip
            arrow
            title="Acesso ao cadastro de clientes"
            placement="right"
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Clientes" />
        </ListItem>
      ) : (
        <></>
      )}
      {/*-------------------------------------------------------------*/}
      {acessaPedidos !== -1 ? (
        <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/pedidos">
          <Tooltip
            arrow
            title="Acesso ao cadastro de pedidos "
            placement="right"
          >
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Pedidos" />
        </ListItem>
      ) : (
        <></>
      )}

      {/*-------------------------------------------------------------*/}
      {acessaPedidos !== -1 ? (
        <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/troca">
          <Tooltip arrow title="Acesso as trocas de produtos" placement="right">
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Trocas" />
        </ListItem>
      ) : (
        <></>
      )}
      {/*-------------------------------------------------------------*/}
      {acessaVisitas !== -1 ? (
        <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/visitas">
          <Tooltip arrow title="Acesso as Visitas Agendadas" placement="right">
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Visitas" />
        </ListItem>
      ) : (
        <></>
      )}

      {/*-------------------------------------------------------------*/}
      {acessaComissao !== -1 ? (
        <ListItem
          sx={{ cursor: 'pointer' }}
          //component={Link}
          to="/comissoes"
          disabled={true}
        >
          <Tooltip
            arrow
            title="Acesso ao relatório de comissões"
            placement="right"
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Comissões" />
        </ListItem>
      ) : (
        <></>
      )}
      {/*-----------------------------------------------------------------*/}

      <Divider />
      <ListItem component={Button} onClick={() => handleLogout(dispatch, url)}>
        <Tooltip arrow title="Sair " placement="right">
          <ListItemIcon>
            <ExitToAppTwoToneIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Sair" />
      </ListItem>
    </List>
  );
}

export default ItemListDrawer;
