import { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  Box,
  Avatar,
} from '@mui/material';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import {
  useAuthDispatch,
  handleLogin,
  useAuthState,
  handleCheckUser,
  carregarTema,
} from '../../contexts/AuthContext';

import Spinner from '../../components/Spinner/Spinner';
import { Redirect, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

function Login() {
  const { id } = useParams();
  const confirm = useConfirm();
  const { autenticado, loading } = useAuthState();
  const [urlBackground, setUrlBackGround] = useState('');
  const dispatch = useAuthDispatch();
  const [values, setValues] = useState({
    email: '',
    password: '',
    empresa: '',
    url: id,
    newThema: null,
  });

  const [nomeBotao, setNomeBotao] = useState('Entrar');
  const [error, setError] = useState({
    email: false,
    password: false,
    empresa: false,
  });

  useEffect(() => {
    switch (id) {
      case 'speed': //speed_comercial
        carregarTema(dispatch, 2);
        setUrlBackGround(`empresa${0}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 0,
          url: id,
          newThema: 2,
        });

        break;
      case 'stylosso': //speed_stylosso-app
        carregarTema(dispatch, 2);
        setUrlBackGround(`empresa${1}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 1,
          url: id,
          newThema: 2,
        });
        break;
      case 'ecodet':
        carregarTema(dispatch, 2);
        setUrlBackGround(`empresa${2}.jpg`);
        setValues({
          email: '',
          password: '',
          empresa: 2,
          url: id,
          newThema: 2,
        });
        break;

      default:
        break;
    }
  }, [id, dispatch]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleBlur = (prop) => (event) => {
    if (values[prop] === '' || values[prop].isUndefined) {
      setError({ ...error, [prop]: true });
    } else {
      setError({ [prop]: false });
    }
  };
  if (autenticado) return <Redirect to="/" />;

  async function handleSubmit(e) {
    if (values.password === '001') {
      handleCheckUser(dispatch, values);
    } else if (values.email && values.password) {
      handleLogin(dispatch, values);
      setNomeBotao('Carregando...');
    } else {
      setNomeBotao('Entrar');
    }
  }
  if (loading) {
    return <Spinner />;
  }
  return (
    <Grid
      container
      component="main"
      sx={{
        height: '97vh',

        margin: 0,
      }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(/images/${urlBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '50%',
          backgroundPosition: 'center',
        }}
      ></Grid>

      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{ bgcolor: 'primary.textColor' }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.buttonInitial' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entrar no sistema
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              type="email"
              id="email"
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              label="Digite  o seu Login"
              name="email"
              autoComplete="email"
              autoFocus
              error={error.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="senha"
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              label="Digite sua Senha"
              type="password"
              id="senha"
              error={error.password}
              autoComplete="senha"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: 'primary.textColor',
              }}
            >
              <LockTwoToneIcon />
              {nomeBotao}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2"></Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2"></Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
