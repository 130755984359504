import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { CloseTwoTone, SaveTwoTone, SearchRounded } from "@mui/icons-material";
import { useAuthState } from "../../../../contexts/AuthContext";
import apiCep from "../../../../api/apiCep";
import { toast } from "react-toastify";
import { useRef } from "react";
import { validaCNPJ, validaCPF } from "../../../../helpers/HelperFunctions";
import api from "../../../../api/api";

export default function ModalClientes({
  open,
  onClose,
  clienteSelecionado,
  inserirCliente,
  alterarCliente,
}) {
  const { usuarioIDLogado, empresaAtiva } = useAuthState();
  const [cliente, setCliente] = useState([
    {
      codigo_cliente: "0",
      razao_social: "",
      tipo: "JURÍDICO",
      nome_fantasia: "",
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      complemento: "",
      telefones: "",
      estado: "",
      cidade: "",
      entrega: "",
      ins_estadual: "",
      cnpj: "",
      contato: "",
      e_mail: "",
      cod_vendedor: usuarioIDLogado,
      app: "SIM",
    },
  ]);
  const [camposComErro, setCamposComErro] = useState({
    razao_social: false,
    tipo: false,
    nome_fantasia: false,
    cep: false,
    endereco: false,
    numero: false,
    bairro: false,
    entrega: false,
    complemento: false,
    telefones: false,
    estado: false,
    cidade: false,
    ins_estadual: false,
    cnpj: false,
    contato: false,
    e_mail: false,
  });

  const refCampoCep = useRef("refCampoCep");
  const refCampoNumero = useRef("refCampoNumero");

  useEffect(() => {
    if (clienteSelecionado) {
      if (clienteSelecionado.length === 0) {
        setCliente({
          codigo_cliente: "0",
          razao_social: "",
          tipo: "JURÍDICO",
          nome_fantasia: "",
          cep: "",
          endereco: "",
          numero: "",
          bairro: "",
          complemento: "",
          telefones: "",
          estado: "",
          cidade: "",
          ins_estadual: "",
          cnpj: "",
          contato: "",
          entrega: "",
          e_mail: "",
          cod_vendedor: usuarioIDLogado,
          app: "SIM",
        });
      } else {
        setCliente({
          codigo_cliente: clienteSelecionado.codigo_cliente,
          razao_social: clienteSelecionado.razao_social,
          tipo: clienteSelecionado.tipo,
          nome_fantasia: clienteSelecionado.nome_fantasia,
          cep: clienteSelecionado.cep,
          endereco: clienteSelecionado.endereco,
          numero: clienteSelecionado.numero,
          bairro: clienteSelecionado.bairro,
          complemento: clienteSelecionado.complemento,
          telefones: clienteSelecionado.telefones,
          estado: clienteSelecionado.estado,
          cidade: clienteSelecionado.cidade,
          entrega: clienteSelecionado.entrrega,
          ins_estadual: clienteSelecionado.ins_estadual,
          cnpj: clienteSelecionado.cnpj,
          contato: clienteSelecionado.contato,
          e_mail: clienteSelecionado.e_mail,
          cod_vendedor: usuarioIDLogado,
          app: "SIM",
        });
      }
    }
  }, [clienteSelecionado]);

  const salvarDados = () => {
    if (validaCliente() === true) {
      if (cliente.codigo_cliente !== "0") {
        alterarCliente(cliente);
      } else {
        inserirCliente(cliente);
      }
    } else {
      toast.error("Preencha todos os dados do cliente corretamente");
    }
  };

  function validaCliente() {
    if (
      cliente.tipo &&
      cliente.razao_social &&
      cliente.nome_fantasia &&
      cliente.cnpj &&
      cliente.cep &&
      cliente.endereco &&
      cliente.numero &&
      cliente.bairro &&
      cliente.cidade &&
      cliente.estado &&
      cliente.telefones
    ) {
      if (cliente.tipo === "FÍSICO" && validaCPF(cliente.cnpj) === true) {
        return true;
      } else if (
        cliente.tipo === "JURÍDICO" &&
        validaCNPJ(cliente.cnpj) === true
      ) {
        return true;
      }
    }
  }

  const handleChange = (prop) => (event) => {
    setCliente({ ...cliente, [prop]: event.target.value.toUpperCase() });
  };

  async function handleSearchCep() {
    if (cliente.cep) {
      await apiCep
        .get(`${cliente.cep}/json`)
        .then(({ data }) => {
          if (data.erro) {
            toast.error("CEP NÃO ENCONTRADO");
          } else {
            setCliente({
              ...cliente,

              endereco: data.logradouro.toUpperCase(),
              bairro: data.bairro.toUpperCase(),
              cidade: data.localidade.toUpperCase(),
              estado: data.uf.toUpperCase(),
            });
            setCamposComErro({ ...camposComErro, cep: false });
            refCampoNumero.current.focus();
          }
        })
        .catch((err) => {
          toast.error("erro" + err.message);
          refCampoCep.current.focus();
          setCamposComErro({ ...camposComErro, cep: true });
        });
    } else {
      toast.error("Digite o CEP");
      refCampoCep.current.focus();
      setCamposComErro({ ...camposComErro, cep: true });
    }
  }
  function confereCPFCNPJ(e) {
    if (cliente.tipo === "JURÍDICO" && validaCNPJ(e.target.value) === false) {
      toast.warning("Atenção CNPJ inválido.");
      setCamposComErro({ ...camposComErro, cnpj: true });
    } else if (
      cliente.tipo === "FÍSICO" &&
      validaCPF(e.target.value) === false
    ) {
      toast.warning("Atenção CPF inválido.");
      setCamposComErro({ ...camposComErro, cnpj: true });
    } else {
      setCamposComErro({ ...camposComErro, cnpj: false });
    }
  }

  function validaCampos(campo) {
    if (cliente[campo] === "") {
      setCamposComErro({ ...camposComErro, [campo]: true });
    } else {
      setCamposComErro({ ...camposComErro, [campo]: false });
    }
  }

  async function confereCadastroCnpj(cnpj) {
    if (cliente.cnpj) {
      try {
        const { data } = await api.post(
          "/clientes/verificarCnpj",
          { cnpj: cnpj },
          {
            headers: { banco: empresaAtiva },
          }
        );
        if (data) {
          toast.error("CNPJ JÁ CADASTRADO ");
        } else {
          toast.success("CNPJ não cadastrado");
        }
      } catch {
        toast.error("Erro ao buscar dados ");
      } finally {
      }
    } else {
      toast.warning("CNPJ deve ser preenchido!");
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Cadastro de Clientes"
      aria-describedby="Cadastro de Clientes"
      hideBackdrop
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "95%", sm: "95%", md: "95%", lg: "70%", xl: "70%" },
          height: {
            xs: "95%",
            sm: "95%",
            md: "80%",
            lg: "50%",
            xl: "55%",
            xxl: "60%",
          },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 2,
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            marginBottom: "5%",
          }}
        >
          <Typography variant="h6">Cadastro Clientes</Typography>

          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <CloseTwoTone sx={{ color: "primary.error" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <FormControl
            sx={{
              width: { xs: "27%", sm: "27%", md: "10%", lg: "10%", xl: "10%" },
            }}
          >
            <TextField
              size="small"
              value={cliente.codigo_cliente}
              label={"Código"}
              fullWidth
              InputProps={{ readOnly: true }}
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "70%", sm: "70%", md: "15%", lg: "15%", xl: "15%" },
              marginLeft: { xs: "3%", sm: "3%", md: "1%", lg: "1%" },
            }}
          >
            <InputLabel id="tipoCliente">Tipo Cliente</InputLabel>
            <Select
              id="tipoCliente"
              error={camposComErro.tipo}
              label="Tipo Cliente"
              onChange={handleChange("tipo")}
              size="small"
              fullWidth
              value={cliente.tipo}
            >
              <MenuItem value="">SELECIONE UMA OPÇÃO</MenuItem>
              <MenuItem value="JURÍDICO">JURÍDICO</MenuItem>
              <MenuItem value="FÍSICO">FÍSICO</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "36%",
                lg: "36%",
                xl: "36%",
              },
              marginTop: { xs: "3%", sm: "3%", md: "0" },
              marginLeft: { xs: "0", sm: "0", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              value={cliente.razao_social}
              onChange={handleChange("razao_social")}
              error={camposComErro.razao_social}
              size="small"
              label="Razão Social"
              onBlur={() => validaCampos("razao_social")}
            />
          </FormControl>
          <FormControl
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "36%",
                lg: "36%",
                xl: "36%",
              },
              marginTop: { xs: "3%", sm: "3%", md: "0" },
              marginLeft: { xs: "0", sm: "0", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              value={cliente.nome_fantasia}
              onChange={handleChange("nome_fantasia")}
              error={camposComErro.nome_fantasia}
              size="small"
              label="Nome Fantasia"
              onBlur={() => validaCampos("nome_fantasia")}
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "20%", sm: "20%", md: "20%", lg: "20%", xl: "20%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "0", sm: "0", md: "0%", lg: "0%" },
            }}
          >
            <TextField
              onChange={handleChange("cnpj")}
              value={cliente.cnpj}
              error={camposComErro.cnpj}
              size="small"
              label={cliente.tipo === "JURÍDICO" ? "CNPJ" : "CPF"}
              onBlur={(e) => confereCPFCNPJ(e)}
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "2%", sm: "2%", md: "2%", lg: "2%", xl: "2%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "1%", sm: "1%", md: "1%", lg: "1%" },
            }}
          >
            <IconButton
              bgcolor="info"
              color="success"
              onClick={() => confereCadastroCnpj(cliente.cnpj)}
            >
              <SearchRounded />
            </IconButton>
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "40%", sm: "40%", md: "20%", lg: "20%", xl: "20%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "3%", sm: "3%", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("ins_estadual")}
              value={cliente.ins_estadual}
              error={camposComErro.ins_estadual}
              size="small"
              label={cliente.tipo === "JURÍDICO" ? "INSC. ESTADUAL" : "R.G"}
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "32%", sm: "30%", md: "10%", lg: "10%", xl: "10%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "1%", sm: "0", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("cep")}
              value={cliente.cep}
              error={camposComErro.cep}
              size="small"
              label="C.E.P"
              inputRef={refCampoCep}
              onBlur={() => handleSearchCep(cliente.cep)}
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "60%", sm: "60%", md: "44%", lg: "47%", xl: "44%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "3%", sm: "3%", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("endereco")}
              value={cliente.endereco}
              size="small"
              label="ENDEREÇO"
              onBlur={() => validaCampos("endereco")}
              error={camposComErro.endereco}
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "30%", sm: "30%", md: "15%", lg: "15%", xl: "15%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "3%", sm: "0", md: "0%", lg: "0%" },
            }}
          >
            <TextField
              onChange={handleChange("numero")}
              value={cliente.numero}
              error={camposComErro.numero}
              onBlur={() => validaCampos("numero")}
              size="small"
              label="NÚMERO"
              inputRef={refCampoNumero}
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "67%", sm: "67%", md: "25%", lg: "25%", xl: "25%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "3%", sm: "3%", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("complemento")}
              value={cliente.complemento}
              error={camposComErro.complemento}
              size="small"
              label="COMPLEMENTO"
            />
          </FormControl>
          <FormControl
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "25%",
                lg: "25%",
                xl: "25%",
              },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "0", sm: "0", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("bairro")}
              value={cliente.bairro}
              error={camposComErro.bairro}
              onBlur={() => validaCampos("bairro")}
              size="small"
              label="BAIRRO"
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "70%", sm: "70%", md: "32%", lg: "32%", xl: "32%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "0", sm: "0", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("cidade")}
              value={cliente.cidade}
              onBlur={() => validaCampos("cidade")}
              error={camposComErro.cidade}
              size="small"
              label="CIDADE"
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "27%", sm: "27%", md: "15%", lg: "15%", xl: "15%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "3%", sm: "3%", md: "0%", lg: "0%" },
            }}
          >
            <TextField
              onChange={handleChange("estado")}
              value={cliente.estado}
              error={camposComErro.estado}
              onBlur={() => validaCampos("estado")}
              size="small"
              label="U.F"
            />
          </FormControl>
          <FormControl
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "50%",
                lg: "50%",
                xl: "50%",
              },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "0%", sm: "0%", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("entrega")}
              value={cliente.entrega}
              error={camposComErro.entrega}
              size="small"
              label="ENTREGA"
              fullWidth
            />
          </FormControl>
          <FormControl
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "30%",
                lg: "30%",
                xl: "30%",
              },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "0%", sm: "0%", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("contato")}
              value={cliente.contato}
              error={camposComErro.contato}
              size="small"
              label="CONTATO"
              fullWidth
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "49%", sm: "49%", md: "30%", lg: "30%", xl: "30%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "0%", sm: "0%", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("e_mail")}
              value={cliente.e_mail}
              error={camposComErro.email}
              size="small"
              label="EMAIL"
            />
          </FormControl>
          <FormControl
            sx={{
              width: { xs: "48%", sm: "48%", md: "22%", lg: "22%", xl: "22%" },
              marginTop: { xs: "3%", sm: "3%", md: "1%" },
              marginLeft: { xs: "3%", sm: "3%", md: "1%", lg: "1%" },
            }}
          >
            <TextField
              onChange={handleChange("telefones")}
              value={cliente.telefones}
              error={camposComErro.telefones}
              onBlur={() => validaCampos("telefones")}
              size="small"
              label="TELEFONE"
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "90%",
            position: "absolute",
            bottom: { xs: 5, sm: 15, md: 15, lg: 15, xl: 15, xxl: 15 },
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button
            onClick={() => salvarDados(cliente)}
            startIcon={<SaveTwoTone />}
            sx={{
              height: {
                xs: "40px",
                sm: "60px",
                md: "60px",
                lg: "60px",
                xl: "60px",
              },
              borderRadius: "20px",
              fontSize: {
                xs: "14px",
                sm: "30px",
                md: "30px",
                lg: "30px",
                xl: "30px",
              },
            }}
            fullWidth
            variant="contained"
          >
            SALVAR
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
