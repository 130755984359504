import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AddCircle, SearchRounded } from '@mui/icons-material';
import api from '../../api/api';
import { useAuthState } from '../../contexts/AuthContext';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import Spinner from '../../components/Spinner/Spinner';
import TitlePage from '../../components/TitlePage/TitlePage';
import { useConfirm } from 'material-ui-confirm';

export default function Pedidos() {
  const [loading, setLoading] = useState(false);

  const { usuarioIDLogado, empresaAtiva, acessos } = useAuthState();
  const [listaPedidos, setListaPedidos] = useState([]);
  const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
  const [pedidoSelecionado, setPedidoSelecionado] = useState([]);
  const [recarregaDados, setRecarregaDados] = useState(false);
  const [modalCadastroPedidos, setModalCadastroPedidos] = useState(false);
  const [modalImprimePedido, setModalImprimePedido] = useState(false);
  const [listaClientes, setListaClientes] = useState([]);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [totalPedido, setTotalPedido] = useState(0);
  const [visualizadorImagem, setVisualizadorImagem] = useState(false);
  const [imagemSelecionada, setImagemSelecionada] = useState('');
  const [loadingImagem, setLoadingImagem] = useState(false);
  const [loadingPedido, setLoadingPedido] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const confirm = useConfirm();
  const nivelAcesso = acessos.filter((acesso) => acesso.grupo === 'PEDIDOS');
  const abrirModalImprimePedido = (dados) => {
    const numPedido = dados.codigo_venda;

    buscaItemsPedido(numPedido);
    setModalImprimePedido(true);
  };
  const fecharModalImprimePedido = () => {
    setModalImprimePedido(false);
    setPedidoSelecionado([]);
    setRecarregaDados(!recarregaDados);
  };
  const abrirModalCadastroPedidos = (dados) => {
    if (dados) {
      const numPedido = dados.codigo_venda;
      buscaItemsPedido(numPedido);
    }
    setModalCadastroPedidos(true);
  };

  const fecharModalCadastroPedidos = () => {
    setModalCadastroPedidos(false);
    setPedidoSelecionado([]);
    setRecarregaDados(!recarregaDados);
  };
  useEffect(() => {
    setLoading(true);
    api
      .post(
        '/clientes/consulta',
        { codigo: usuarioIDLogado },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch((err) => {
        toast.error('Erro ao Carregar Clientes' + err.response.data);
        console.log(err.response.data);
      })
      .finally(() => setLoading(false));
  }, [usuarioIDLogado, empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .post(
        '/pedidos/consulta',
        {
          pedido: 0,
          tipo: 'VENDA',
          cliente: 0,
          vendedor: usuarioIDLogado,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setListaPedidos(data);
        setPedidosFiltrados(data);
      })
      .catch((err) => {
        toast.error('Erro ao Carregar PEDIDOS' + err.response.data);
        console.log(err.response.data);
      })
      .finally(() => setLoading(false));
  }, [usuarioIDLogado, empresaAtiva, recarregaDados]);

  function buscaProdutos(cliente) {
    api
      .post(
        '/produtos/consulta',
        {
          cliente: cliente,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setListaProdutos(data);
      })
      .catch((err) => {
        toast.error('Erro ao Carregar Produtos' + err.response.data);
      })
      .finally(() => setLoading(false));
  }

  function buscaItemsPedido(numPedido) {
    setLoadingPedido(true);
    api
      .post(
        '/pedidos/itens/consulta',
        {
          pedido: numPedido,
          cliente: '0',
          produto: '0',
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setPedidoSelecionado(data);
        console.log(data);
        const inicio = 0;
        setTotalPedido(
          data.reduce(
            (accumulator, currentValue) => accumulator + currentValue.total,
            inicio
          )
        );
      })
      .catch((err) => toast.error('Erro ao buscar dados do Pedido '))
      .finally(() => setLoadingPedido(false));
  }

  function apagaPedido(numPedido, codigoVendaSv) {
    if (codigoVendaSv !== 0) {
      toast.error(
        'Você não pode apagar o pedido pois o mesmo se encontra em processo de faturamento !'
      );
    } else {
      confirm({
        description: `Deseja apagar o pedido ${numPedido} `,
        title: 'Confirme a operação!',
        confirmationText: 'SIM',
        cancellationText: 'NÃO',
      })
        .then(() => {
          api
            .delete('/pedidos/apagar', {
              headers: { banco: empresaAtiva },

              data: {
                pedido: numPedido,
              },
            })
            .then((res) => {
              setRecarregaDados(!recarregaDados);
              toast.success('Pedido apagado com sucesso!');
            })
            .catch((err) => {
              toast.error('Erro ao apagar Pedido!');
            });
        })
        .catch(() => {
          /* ... */
        });
    }
  }

  const salvarPedido = (pedidos) => {
    if (pedidos.itens.length === 0) {
      toast.error('Não existe produtos na venda !');
    } else if (pedidos.codigo_venda > 0) {
      api
        .post('pedidos/alterar', pedidos, {
          headers: { banco: empresaAtiva },
        })
        .then((response) => {
          toast.success('Pedido Salvo com Sucesso');
          fecharModalCadastroPedidos();
          setLoading(false);
        })
        .catch((err) => {
          toast.error('Erro ao salvar dados' + err);
          console.log(err);
          setLoading(false);
        });
    } else {
      api
        .post('pedidos/inserir', pedidos, {
          headers: { banco: empresaAtiva },
        })
        .then((response) => {
          toast.success('Pedido Salvo com Sucesso');
          fecharModalCadastroPedidos();
          setLoading(false);
        })
        .catch((err) => {
          toast.error('Erro ao salvar dados' + err);
          console.log(err);
          setLoading(false);
        });
    }
  };

  const filtraPedidos = (parametro) => {
    const pedidoEncontrado = listaPedidos.filter(
      (pedido) =>
        pedido.razao_social.toLowerCase().includes(parametro) ||
        pedido.codigo_cliente === parseInt(parametro)
    );
    setPedidosFiltrados(pedidoEncontrado);
  };

  function abrirVisualizadorImagem(codigo) {
    setVisualizadorImagem(true);
    setLoadingImagem(true);

    api
      .post(
        '/produtos/imagem/consulta',
        { codigo: codigo },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setImagemSelecionada(data.imagem);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingImagem(false);
      });
  }

  function fecharVisualizadorImagem() {
    setVisualizadorImagem(false);
    setImagemSelecionada('');
  }
  if (loading) {
    return (
      <Box
        sx={{
          maxWidth: '100%',
          width: '90%',
          padding: 0,
          margin: 0,
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: {
          xs: '80%',
          sm: '80%',
          md: '90%',
          lg: '90%',
          xl: '95%',
        },
        width: {
          xs: '80%',
          sm: '80%',
          md: '90%',
          lg: '90%',
          xl: '95%',
        },
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          flexWrap: 'wrap',
          padding: 0,
          margin: 0,
          top: 75,
          zIndex: 9,
          height: '15vh',
          width: {
            xs: '70%',
            sm: '80%',
            md: '90%',
            lg: '90%',
            xl: '95%',
          },
          maxWidth: {
            xs: '70%',
            sm: '80%',
            md: '90%',
            lg: '90%',
            xl: '95%',
          },
          bgcolor: 'background.paper',
        }}
      >
        <TitlePage title={'Pedidos'} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1%',
            marginBottom: '5%',
            width: {
              xs: '90%',
              sm: '90%',
              md: '90%',
              lg: '90%',
              xl: '100%',
            },

            borderBottom: '1px solid',
          }}
        >
          {!matches ? (
            <Tooltip title="Adidionar um pedido">
              <IconButton onClick={() => abrirModalCadastroPedidos()}>
                <AddCircle sx={{ color: 'primary.success' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Adidionar um pedido">
              <Button
                variant="contained"
                size="small"
                startIcon={<AddCircle sx={{ color: 'primary.success' }} />}
                onClick={() => abrirModalCadastroPedidos()}
              >
                Novo
              </Button>
            </Tooltip>
          )}
          <FormControl
            sx={{ width: '85%', borderRadius: '5%', marginLeft: '5%' }}
          >
            <TextField
              onChange={(e) => filtraPedidos(e.target.value.toLowerCase())}
              placeholder="Pesquise o pedido..."
              id="searchBox"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
              size="small"
              variant="outlined"
            />
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          zIndex: '-1',
          marginTop: {
            xs: '15vh',
            sm: '16vh',
            md: '19vh',
            lg: '21vh',
            xl: '24vh',
            xxl: '40vh',
          },
        }}
      >
        <TabelaResultados
          lista={pedidosFiltrados}
          nivelAcesso={nivelAcesso}
          abrirModalCadastroPedidos={abrirModalCadastroPedidos}
          fecharModalCadastroPedidos={fecharModalCadastroPedidos}
          modalCadastroPedidos={modalCadastroPedidos}
          pedidoSelecionado={pedidoSelecionado}
          salvarPedido={salvarPedido}
          totalPedido={totalPedido}
          apagaPedido={apagaPedido}
          abrirModalImprimePedido={abrirModalImprimePedido}
          fecharModalImprimePedido={fecharModalImprimePedido}
          modalImprimePedido={modalImprimePedido}
          listaClientes={listaClientes}
          listaProdutos={listaProdutos}
          buscaProdutos={buscaProdutos}
          visualizadorImagem={visualizadorImagem}
          abrirVisualizadorImagem={abrirVisualizadorImagem}
          fecharVisualizadorImagem={fecharVisualizadorImagem}
          imagemSelecionada={imagemSelecionada}
          loadingImagem={loadingImagem}
          loadingPedido={loadingPedido}
        />
      </Box>
    </Box>
  );
}
