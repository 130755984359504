import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AddCircle, SearchRounded } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import api from '../../api/api';
import { useAuthState } from '../../contexts/AuthContext';
import TabelaResultados from './TabelaResultados/TabelaResultados';
import Spinner from '../../components/Spinner/Spinner';
import TitlePage from '../../components/TitlePage/TitlePage';
import moment from 'moment';

export default function Visitas() {
  const [loading, setLoading] = useState(false);
  const { usuarioIDLogado, empresaAtiva } = useAuthState();
  const [listaVisitas, setListaVisitas] = useState([]);
  const [visitasFiltradas, setVisitasFiltradas] = useState([]);

  const [modalReagendaVisitas, setModalReagendaVisitas] = useState(false);
  const [recarregaDados, setRecarregaDados] = useState(false);
  const [modalCadastroVisitas, setModalCadastroVisitas] = useState(false);
  const [modalCancelaVisitas, setModalCancelaVisitas] = useState(false);
  const [modalConfirmaVisitas, setModalConfirmaVisitas] = useState(false);
  const [listaClientes, setListaClientes] = useState([]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const confirm = useConfirm();
  /*----------------------------------------*/
  const abrirModalCadastroVisitas = () => {
    setModalCadastroVisitas(true);
  };

  const fecharModalCadastroVisitas = () => {
    setModalCadastroVisitas(false);
    setRecarregaDados(!recarregaDados);
  };
  /*----------------------------------------*/
  const abrirModalReagendaVisitas = () => {
    setModalReagendaVisitas(true);
  };

  const fecharModalReagendaVisitas = () => {
    setModalReagendaVisitas(false);
  };
  /*----------------------------------------*/
  const abrirModalConfirmaVisitas = () => {
    setModalConfirmaVisitas(true);
  };

  const fecharModalConfirmaVisitas = () => {
    setModalConfirmaVisitas(false);
  };
  /*----------------------------------------*/
  const abrirModalCancelaVisitas = () => {
    setModalCancelaVisitas(true);
  };

  const fecharModalCancelaVisitas = () => {
    setModalCancelaVisitas(false);
  };
  /*----------------------------------------*/
  useEffect(() => {
    setLoading(true);
    api
      .post(
        '/clientes/consulta',
        { codigo: usuarioIDLogado },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setListaClientes(data);
      })
      .catch((err) => {
        toast.error('Erro ao Carregar Clientes' + err.response.data);
        console.log(err.response.data);
      })
      .finally(() => setLoading(false));
  }, [usuarioIDLogado, empresaAtiva]);

  useEffect(() => {
    setLoading(true);
    api
      .post(
        '/visitas/consulta',
        {
          codigo: usuarioIDLogado,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setListaVisitas(data);
        setVisitasFiltradas(data);
        console.log(data);
      })
      .catch((err) => {
        toast.error('Erro ao Carregar Clientes' + err.response.data);
        console.log(err.response.data);
      })
      .finally(() => setLoading(false));
  }, [usuarioIDLogado, empresaAtiva, recarregaDados]);

  const cancelaVisita = (codigoVisita, motivo) => {
    confirm({
      title: 'Confirmar a Operação',
      description: 'Deseja Cancelar a Visita',
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    })
      .then(() => {
        api
          .put(
            '/visitas/cancel/alterar',
            { codigo: codigoVisita, motivo },
            {
              headers: { banco: empresaAtiva },
            }
          )
          .then(({ data }) => {
            toast.success('Visita cancelada com sucesso');
            setModalCancelaVisitas(false);
            setRecarregaDados(!recarregaDados);
          })
          .catch((err) => {
            toast.error('Erro ao agendar visita!');
            console.log(err.response.data);
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        /* ... */
      });
  };

  const confirmaVisita = (codigoVisita, motivo) => {
    confirm({
      title: 'Confirmar a Operação',
      description: 'Deseja Confirmar a Visita',
      confirmationText: 'SIM',
      cancellationText: 'NÃO',
    })
      .then(() => {
        api
          .put(
            '/visitas/confirma/alterar',
            { codigo: codigoVisita, motivo: motivo },
            {
              headers: { banco: empresaAtiva },
            }
          )
          .then(({ data }) => {
            toast.success('Visita confirmada com sucesso');
            setModalConfirmaVisitas(false);
            setRecarregaDados(!recarregaDados);
          })
          .catch((err) => {
            toast.error('Erro ao agendar visita!');
            console.log(err.response.data);
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        /* ... */
      });
  };

  const agendaVisita = (novaVisita) => {
    if (
      !novaVisita.vis_cliente ||
      !novaVisita.vis_data ||
      !novaVisita.vis_solicitante ||
      !novaVisita.vis_obs
    ) {
      toast.error('Os campos devem ser preenchidos corretamente ');
    } else {
      api
        .post('/visitas/inserir', novaVisita, {
          headers: { banco: empresaAtiva },
        })
        .then(({ data }) => {
          toast.success('Visita agendada  com sucesso');
          setModalCadastroVisitas(false);
          setRecarregaDados(!recarregaDados);
        })
        .catch((err) => {
          toast.error('Erro ao agendar visita!');
          console.log(err.response.data);
        })
        .finally(() => setLoading(false));
    }
  };
  const reagendaVisita = (codigoVisita, data, obs) => {
    if (!codigoVisita || !data || !obs) {
      toast.error('Os campos devem ser preenchidos corretamente ');
    } else {
      api
        .put(
          '/visitas/reagenda/alterar',
          { codigo: codigoVisita, data: data, vis_obs: obs },
          {
            headers: { banco: empresaAtiva },
          }
        )
        .then(({ data }) => {
          toast.success('Visita reagendada com sucesso');
          setModalReagendaVisitas(false);
          setRecarregaDados(!recarregaDados);
        })
        .catch((err) => {
          toast.error('Erro ao agendar visita!');
          console.log(err.response.data);
        })
        .finally(() => setLoading(false));
    }
  };
  const filtraVisitas = (parametro) => {
    const dataFormatada = moment(parametro, 'DD/MM/YYYY').format('YYYY-MM-DD');

    const visitaEncontrada = listaVisitas.filter(
      (visita) =>
        visita.nome_fantasia.toLowerCase().includes(parametro.toLowerCase()) ||
        visita.codigo_cliente === parseInt(parametro) ||
        visita.vis_data.includes(dataFormatada)
    );
    setVisitasFiltradas(visitaEncontrada);
  };

  if (loading) {
    return (
      <Box
        sx={{
          maxWidth: '100%',
          width: '100%',
          padding: 0,
          margin: 0,
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: {
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '90%',
          xl: '95%',
        },
        width: {
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '90%',
          xl: '95%',
        },
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          flexWrap: 'wrap',
          padding: 0,
          margin: 0,
          top: 75,
          zIndex: 9,
          height: '15vh',
          width: {
            xs: '75%',
            sm: '90%',
            md: '90%',
            lg: '90%',
            xl: '95%',
          },
          maxWidth: '100%',
          bgcolor: 'background.paper',
        }}
      >
        <TitlePage title={'Visitas'} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1%',
            marginBottom: '5%',
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '100%',
              xl: '100%',
            },
            padding: '1%',
            borderBottom: '1px solid',
          }}
        >
          {!matches ? (
            <Tooltip title="Agendar uma Visita">
              <IconButton onClick={() => abrirModalCadastroVisitas()}>
                <AddCircle sx={{ color: 'primary.success' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Agendar uma Visita">
              <Button
                variant="contained"
                size="small"
                startIcon={<AddCircle sx={{ color: 'primary.success' }} />}
                onClick={() => abrirModalCadastroVisitas()}
              >
                Nova
              </Button>
            </Tooltip>
          )}
          <FormControl
            sx={{ width: '85%', borderRadius: '5%', marginLeft: '5%' }}
          >
            <TextField
              onChange={(e) => filtraVisitas(e.target.value)}
              id="searchBox"
              placeholder="Pesquise uma visita ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
              size="small"
              variant="outlined"
            />
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          zIndex: '-1',

          marginTop: {
            xs: '15vh',
            sm: '16vh',
            md: '19vh',
            lg: '21vh',
            xl: '24vh',
            xxl: '40vh',
          },
        }}
      >
        <TabelaResultados
          lista={visitasFiltradas}
          abrirModalCadastroVisitas={abrirModalCadastroVisitas}
          fecharModalCadastroVisitas={fecharModalCadastroVisitas}
          modalCadastroVisitas={modalCadastroVisitas}
          listaClientes={listaClientes}
          buscaVisitas={filtraVisitas}
          agendaVisita={agendaVisita}
          reagendaVisita={reagendaVisita}
          confirmaVisita={confirmaVisita}
          cancelaVisita={cancelaVisita}
          modalConfirmaVisitas={modalConfirmaVisitas}
          modalCancelaVisitas={modalCancelaVisitas}
          abrirModalCancelaVisitas={abrirModalCancelaVisitas}
          abrirModalConfirmaVisitas={abrirModalConfirmaVisitas}
          abrirModalReagendaVisitas={abrirModalReagendaVisitas}
          fecharModalCancelaVisitas={fecharModalCancelaVisitas}
          fecharModalConfirmaVisitas={fecharModalConfirmaVisitas}
          fecharModalReagendaVisitas={fecharModalReagendaVisitas}
          modalReagendaVisitas={modalReagendaVisitas}
        />
      </Box>
    </Box>
  );
}
