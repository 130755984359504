import { Grid, Box, Typography, Stack, TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../api/api';
import MainCard from '../../components/Cards/MainCard';
import TitlePage from '../../components/TitlePage/TitlePage';
import { useAuthState } from '../../contexts/AuthContext';
import Spinner from '../../components/Spinner/Spinner';
function HomePage() {
  const { empresaAtiva, usuarioIDLogado } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [vendasDiarias, setVendasDiarias] = useState([]);
  const [vendasMensais, setVendasMensais] = useState([]);
  const [visitasDiarias, setVisitasDiarias] = useState([]);
  const [visitasMensais, setVisitasMensais] = useState([]);
  const [clientesMensais, setClientesMensais] = useState([]);
  const [clientesDiarios, setClientesDiarios] = useState([]);
  const [dataPesquisa, setDataPesquisa] = useState(
    moment().format('YYYY-MM-DD')
  );

  useEffect(() => {
    setLoading(true);
    api
      .post(
        '/home/consulta',
        {
          codigo_vendedor: usuarioIDLogado,
          data: dataPesquisa,
        },
        {
          headers: { banco: empresaAtiva },
        }
      )
      .then(({ data }) => {
        setDados(data);
      })
      .catch((err) => {
       // toast.error('Erro ao Buscar Dados ');
        console.log(err.response.data);
      })
      .finally(() => setLoading(false));
  }, [dataPesquisa, empresaAtiva, usuarioIDLogado]);

  useEffect(() => {
    setVendasMensais(dados.filter((item) => item.tipo === 'VENDA MES'));
    setVendasDiarias(dados.filter((item) => item.tipo === 'VENDA DIA'));
    setVisitasMensais(dados.filter((item) => item.tipo === 'VISITA MES'));
    setVisitasDiarias(dados.filter((item) => item.tipo === 'VISITA DIA'));
    setClientesMensais(dados.filter((item) => item.tipo === 'CLIENTE MES'));
    setClientesDiarios(dados.filter((item) => item.tipo === 'CLIENTE DIA'));
  }, [dados]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <Grid
      container
      rowSpacing={4.5}
      columnSpacing={2.75}
      sx={{ mb: { maxWidth: '90vw' } }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Stack component="form" noValidate spacing={3} mb={1}>
          <TextField
            id="date"
            label="Data Inicial"
            type="date"
            defaultValue={dataPesquisa}
            onChange={(e) => setDataPesquisa(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <MainCard title="Vendas Mensais">
            <Stack alignItems={'center'}>
              <Typography variant="h3">
                {vendasMensais.length > 0
                  ? vendasMensais[0].valor > 0
                    ? vendasMensais[0].valor.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : 'R$0,00'
                  : 'R$0,00'}
              </Typography>
              <Typography variant="subtitle">no total</Typography>
            </Stack>
          </MainCard>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <MainCard title="Vendas Diarias">
            <Stack alignItems={'center'}>
              <Typography variant="h3">
                {vendasDiarias.length > 0
                  ? vendasDiarias[0].valor > 0
                    ? vendasDiarias[0].valor.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : 'R$ 0,00'
                  : 'R$ 0,00'}
              </Typography>
              <Typography variant="subtitle">no total</Typography>
            </Stack>
          </MainCard>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <MainCard title="Visitas Mensais">
            <Stack alignItems={'center'}>
              <Typography variant="h3">
                {visitasMensais.length > 0
                  ? visitasMensais[0].qtde > 0
                    ? visitasMensais[0].qtde
                    : '0'
                  : '0'}
              </Typography>
              <Typography variant="subtitle">
                Clientes Visitados esse mês
              </Typography>
            </Stack>
          </MainCard>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <MainCard title="Visitas Diarias">
            <Stack alignItems={'center'}>
              <Typography variant="h3">
                {visitasDiarias.length > 0
                  ? visitasDiarias[0].qtde > 0
                    ? visitasDiarias[0].qtde
                    : '0'
                  : '0'}
              </Typography>
              <Typography variant="subtitle">
                Clientes visitados hoje
              </Typography>
            </Stack>
          </MainCard>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <MainCard title="Clientes Mensais">
            <Stack alignItems={'center'}>
              <Typography variant="h6" color="none" sx={{ opacity: '0.0' }}>
                .
              </Typography>

              <Typography variant="h3">
                {clientesMensais.length > 0
                  ? clientesMensais[0].qtde > 0
                    ? clientesMensais[0].qtde
                    : '0'
                  : '0'}
              </Typography>
              <Typography variant="subtitle">
                Clientes cadastrados esse mês
              </Typography>
            </Stack>
          </MainCard>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <MainCard title="Clientes Diarios">
            <Stack alignItems={'center'}>
              <Typography variant="h6" color="none" sx={{ opacity: '0.0' }}>
                .
              </Typography>

              <Typography variant="h3">
                {clientesDiarios.length > 0
                  ? clientesDiarios[0].qtde > 0
                    ? clientesDiarios[0].qtde
                    : '0'
                  : '0'}
              </Typography>
              <Typography variant="subtitle">
                Clientes cadastrados hoje{' '}
              </Typography>
            </Stack>
          </MainCard>
        </Box>
      </Grid>
    </Grid>
  );
}

export default HomePage;
