import {
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material';
import { DeleteForeverTwoTone, SearchRounded } from '@mui/icons-material';

const PesquisaComponente = ({ efetuaPesquisa, textoPesquisa }) => {
  return (
    <Box>
      <FormControl sx={{ m: 1, mt: 0.3, width: '90%' }}>
        <TextField
          value={textoPesquisa}
          onChange={(e) => efetuaPesquisa(e)}
          id="searchBox"
          placeholder="Pesquise o produto..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
          size="small"
          variant="outlined"
        />
      </FormControl>
      <IconButton
        sx={{ bgcolor: 'primary.main' }}
        onClick={() => efetuaPesquisa('')}
      >
        <DeleteForeverTwoTone sx={{ color: 'primary.error' }} />
      </IconButton>
    </Box>
  );
};
export default PesquisaComponente;
